import Image from "next/image"

export default function LogoDivider({ blok, a11yHidden = false }) {
  return (
    <div className="content-divider my-16" aria-hidden={a11yHidden}>
      <div className="max-w-screen-xl mx-auto relative">
        {blok?.logo?.filename && (
          <div className="min-w-[125px] h-[125px] w-max mx-auto absolute -translate-y-1/2 left-1/2 -translate-x-1/2">
            <Image
              className="h-full w-max object-contain"
              src={blok?.logo.filename}
              alt={blok?.logo.alt}
              title={blok?.logo.title}
              width={140}
              height={140}
              placeholder="blur"
              blurDataURL={blok?.logo.blurDataURL}
            />
          </div>
        )}
        <hr className="block w-full mx-auto border-t-0 h-4 divider" />
      </div>
    </div>
  )
}
