import NextImage from "next/image"
import Image from "components/Image"
import cn from "classnames"
import richText from "utilities/richText"
import Divider from "components/DynamicComponent/atoms/Divider"
import LogoDivider from "components/DynamicComponent/atoms/LogoDivider"

const SingleImage = ({ image }) => (
  <Image
    src={image?.filename}
    alt={image?.alt}
    placeholder={image?.blurDataURL ? "blur" : "empty"}
    blurDataURL={image?.blurDataURL}
    style={{ width: "100%", height: "auto" }}
    sizes="100vw, (min-width: 768px) 35vw"
    width={800}
    height={800}
  />
)

export default function DividerImageAndContent({ blok }) {
  const section = cn(" bg-grey-cool-tint px-5", {
    "bg-primary": blok.background_color === "primary",
    "bg-white": blok.background_color === "white",
  })

  const container = cn(
    "image-content-container flex flex-col gap-4 md:gap-8 items-center py-12 mx-auto max-w-screen-xl",
    {
      "md:flex-row-reverse justify-end": blok.orientation === "content_first",
      "md:flex-row": blok.orientation === "image_first",
    },
  )

  const image = "w-full z-10 basis-1/2 hidden md:block xl:self-end"

  return (
    <section className={section}>
      <div className={container}>
        <div className={image}>
          {blok.image ? (
            <SingleImage image={blok.image} />
          ) : (
            <NextImage
              src="/assets/placeholder.png"
              alt="Husband Wife Law team"
              style={{ width: "100%", height: "auto" }}
              sizes="100vw, (min-width: 768px) 35vw"
              width={800}
              height={800}
            />
          )}
        </div>

        <div className={cn("basis-1/2 image-content", { "text-white": blok.background_color === "primary" })}>
          <LogoDivider blok={{ logo: blok.logo }} />
          <h2 className={cn("text-black mb-6 text-center", { "text-white": blok.background_color === "primary" })}>
            {blok.heading}
          </h2>
          <div className="md:hidden">
            {blok.image ? (
              <SingleImage image={blok.image} />
            ) : (
              <NextImage
                src="/assets/placeholder.png"
                alt="Husband Wife Law team"
                style={{ width: "100%", height: "auto" }}
                sizes="100vw, (min-width: 768px) 35vw"
                width={800}
                height={800}
              />
            )}
          </div>
          <div className="leading-8 prose-p:mb-6">{richText(blok.content)}</div>
          <Divider />
        </div>
      </div>
    </section>
  )
}
